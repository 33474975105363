<template>
  <b-card>
     <validation-observer ref="changeAccPassRules">
      <b-form
      @submit="onSubmit"
      id="FORMID"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|password"
              >
                <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="newPasswordValue"
                        :type="passwordFieldTypeNew"
                        name="new-password"
                        placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                  >
                  <b-input-group class="input-group-merge">

                      <b-form-input
                        id="account-retype-new-password"
                        v-model="RetypePassword"
                        :type="passwordFieldTypeRetype"
                        name="retype-password"
                        placeholder="New Password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              form="FORMID"
              @click.prevent="onSubmit"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
     </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import User from '../../Helpers/User'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,ValidationObserver,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required, confirmed, password,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit(event){

        event.preventDefault()
        this.$refs.changeAccPassRules.validate().then(success => {
            if(success){
              try{

                const data =  { 'old_password':this.passwordValueOld,
                                      'new_password':this.newPasswordValue,
                                      'confirm_password': this.RetypePassword,
                                      'userId': User.id()}
                
                axios.post(process.env.VUE_APP_API+'/change-acc-password', data, {
                    headers: {
                      'Authorization': `Bearer ${User.token()}`
                    },
                  })
                  .then(res=>{
                          if(res.data.success){
                              this.passwordValueOld  = ''
                              this.newPasswordValue = ''
                              this.RetypePassword = ''
                              this.$toast
                              ({
                                  component: ToastificationContent,
                                  props: {
                                      title: 'Password Changed Successfully',
                                      icon: 'AlertOctagonIcon',
                                      text: '',
                                      variant:'success',
                                      },     
                                  },
                                  {
                                  position:'top-right'
                              })
                          }
                          else{
                              this.$toast
                              ({
                                  component: ToastificationContent,
                                  props: {
                                      title: 'Failed',
                                      icon: 'AlertOctagonIcon',
                                      text: res.data.message,
                                      variant:'danger',
                                      },     
                                  },
                                  {
                                  position:'top-right'
                              })
                          }
                  })                
              }
              catch(error){
              console.log(error)
              }
            }
      })
    },
  },
}
</script>
