<template>
<div class="position-relative">
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Edit Profile</span>
        </template>

        <edit-profile
        :userData="userData"
        @refresh = "getUserData"
        />
      </b-tab>

      <b-tab>
          <template #title>
              <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
              />
              <span class="font-weight-bold">Change Account Password</span>
          </template>

        <change-profile-password />
      </b-tab>

    </b-tabs>

    <b-overlay
    :show="loader"
    no-wrap
    spinner-variant="primary"
    />

  </div>
</template>

<script>
import { BTabs, BTab,BOverlay } from 'bootstrap-vue'
import ChangeProfilePassword from './ChangeProfilePassword.vue'
import EditProfile from './EditProfile.vue'
import axios from 'axios'
import User from '../../Helpers/User'
import store from '@/store/index'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    ChangeProfilePassword,
    EditProfile
  },
  data(){
    return{
      userData : {},
      loader:false
    } 
  },
  created()
  {
      this.getUserData()
  },
  methods:
  {
      getUserData(){
          this.loader = true
          const config = {
                headers: { Authorization: `Bearer ${User.token()}` }
            };

          axios.get(process.env.VUE_APP_API + "/user", config)
            .then((res) => {
              store.commit('myStore/USER_AVATAR', res.data.avatar)
              this.loader = false
              this.userData = res.data;
            })
            .catch((error) => {
              this.loader = false
              console.log(error);
            });
      }
  }
}
</script>
